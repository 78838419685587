import { ReactNode } from 'react';
import { GoogleReCaptchaCheckbox } from '@google-recaptcha/react';

const RecaptchaCheckBox = ({
  handleVerifyRecaptcha,
  handleRecaptchaExpired,
  action,
  reloadKey,
}: {
  handleVerifyRecaptcha: (recaptchaToken: string) => void;
  handleRecaptchaExpired: () => void;
  action: string;
  reloadKey: number;
}): ReactNode => (
  <GoogleReCaptchaCheckbox
    key={reloadKey}
    onChange={handleVerifyRecaptcha}
    action={action}
    expiredCallback={handleRecaptchaExpired}
    language="ja"
  />
);

export default RecaptchaCheckBox;
